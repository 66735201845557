import { useEffect, useCallback, useContext, createContext } from 'react'
import { useImmer } from 'use-immer'
import { http } from '../http'
import { normalizr } from '../helpers'

const StoreContext = createContext(null)

const initialStore = {
  'device.list': { status: 'loading', byId: {}, ids: [] },
  'supplier.list': { status: 'loading', byId: {}, ids: [] },
  'delivery.list': { status: 'loading', byId: {}, ids: [] },
  'company.list': { status: 'loading', byId: {}, ids: [] },
  'inventory': { status: 'loading' }
}

const copy = (draft, payload) => Object.keys(payload).forEach(key => draft[key] = payload[key])

function produce(key, draft, res) {
  const { ok, data: payload } = res

  if (!ok) {
    draft.status = 'rejected'
    return
  }

  switch (key) {
    case 'inventory':
      draft.status = 'resolved'
      copy(draft, payload)
      return
    case 'device.list':
    case 'supplier.list':
    case 'delivery.list':
    case 'company.list':
      const { byId, ids } = normalizr(payload)
      draft.status = 'resolved'
      draft.byId = byId
      draft.ids = ids
      return
    default:
      //
  }
}
export const StoreProvider = props => {
  const { children } = props
  const [store, setStore] = useImmer(initialStore)

  useEffect(() => {
    Promise.all([
      http.fetch('/app.device.list'),
      http.fetch('/app.supplier.list'),
      http.fetch('/app.delivery.list'),
      http.fetch('/app.company.list'),
      http.fetch('/app.inventory.read'),
    ]).then(ary => {
      setStore(draft => {
        Object.keys(draft).forEach((key, i) =>  {
          produce(key, draft[key], ary[i])
        })
      })
    })

  }, [setStore])

  return (
    <StoreContext.Provider value={[store, setStore]}>
      {children}
    </StoreContext.Provider>
  )
}

export const useStore = (index) => {
  const [store, setStore] = useContext(StoreContext)

  return [
    store[index],
    useCallback(updater => setStore(draft => updater(draft[index])), [index, setStore])
  ]
}