import { f } from '../helpers'
import { List } from './List'
import { useStore } from './StoreProvider'
import styles from './Card.module.css'
export const SupplierList = () => {
  const [{ byId }] = useStore('company.list')
  const [store] = useStore('supplier.list')
  const status = store.status
  const data = store.ids.map(id => store.byId[id])

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>Suppliers</div>
      </div>
      <div className={styles.content}>
        <List
          status={status}
          columns={['Company', 'Orders', 'Volume']}
          rows={data.map(cur => ([
            byId[cur.id]?.name || cur.id,
            cur.deliveryCount,
            f.L(cur.restockedVolumeSum),
          ]))}
        />
      </div>
    </div>
  )
}