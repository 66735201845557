import { useState } from 'react'
import { createPortal } from 'react-dom'
import { http } from '../http'
import { normalizr } from '../helpers'
import { useStore } from './StoreProvider'
import { Button } from './Button'
import { Modal } from './Modal'
import styles from './Form.module.css'

export const DeviceAddButton = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button onClick={() => setOpen(true)}>+</Button>
      {open && createPortal(
        <Modal>
          <Form onClose={() => setOpen(false)}/>
        </Modal>,
        document.body
      )}
    </>
  )
}

function Form(props) {
  const { onClose } = props
  const setStore = useStore('device.list')[1]
  const [status, setStatus] = useState('resolved')
  const [formValue, setFormValue] = useState('')

  return (
    <div className={styles.root}>
      <div className={styles.title}>Add new pump</div>
      <div className={styles.content}>
        {'rejected' === status && <div className={styles.error}>Something went wrong.</div>}
        <div className={styles.group}>
          <div className={styles.label}>Device ID</div>
          <input
            className={styles.input}
            type="text"
            onChange={e => setFormValue(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.actions}>
        <Button size="lg" variant="secondary" onClick={onClose}>Cancel</Button>
        <Button size="lg" onClick={handleCommit}>Confirm</Button>
      </div>
    </div>
  )

  function handleCommit() {
    if ('' === formValue || 'pending' === status) {
      return
    }

    setStatus('pending')

    http.fetch('/app.device.connect', { device: formValue }).then(r1 => {
      if (!r1.ok) {
        console.error(r1)
        setStatus('rejected')
        return
      }

      http.fetch('/app.device.list').then(r2 => {
        if (r2.ok) {
          setStore(draft => {
            const { ids, byId } = normalizr(r2.data)
            draft.ids = ids
            draft.byId = byId
          })
        }
        onClose()
      })
    })
  }
}