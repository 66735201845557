import styles from './List.module.css'

export const List = props => {
  const { columns = [], rows = [], status } = props

  if (undefined === status || 'loading' === status) {
    return <div className={styles.placeholder}>Loading...</div>
  } else if (status === 'rejected') {
    return <div className={styles.placeholder}>Something went wrong</div>
  } else if (status === 'resolved' && 0 === rows.length) {
    return <div className={styles.placeholder}>No records found</div>
  }

  return (
    <div className={styles.root}>
      <div className={styles.columns}>
        <div className={styles.row}>
          {columns.map((cur, i) => <div key={i}>{cur}</div>)}
        </div>
      </div>
      <div className={styles.rows}>
        {rows.map((row, i) => (
          <div key={i} className={styles.row}>
            {row.map((cur, n) => <div key={n}>{cur}</div>)}
          </div>
        ))}
      </div>
    </div>
  )
}