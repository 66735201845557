export const normalizr = ary => {
  return {
    ids: ary.map(cur => cur.id),
    byId: ary.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {})
  }
}

const pad = (str, maxLength) => String(str).padStart(maxLength, '0')

export const f = {
  sec: ms => ms / Math.pow(10, 3),
  L: ml => `${ml / Math.pow(10, 3)} L`,
  DateTime: str => {
    const dt = new Date(str)
    const date = [
      pad(dt.getUTCDate(), 2),
      pad(dt.getUTCMonth() + 1, 2),
      pad(dt.getUTCFullYear(), 4)
    ].join('.')
    const time = [
      pad(dt.getUTCHours(), 2),
      pad(dt.getUTCMinutes(), 2),
      pad(dt.getUTCSeconds(), 2),
    ].join(':')

    return `${date} ${time}`
  }
}

export const clsx = (...args) => args.filter(cur => Boolean(cur)).join(' ')