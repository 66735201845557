import { f } from '../helpers'
import { List } from './List'
import { useStore } from './StoreProvider'
import { DeliveryAddButton } from './DeliveryAddButton'
import styles from './Card.module.css'
export const DeliveryList = () => {
  const [{ byId }] = useStore('company.list')
  const [store] = useStore('delivery.list')
  const status = store.status
  const data = store.ids.map(id => store.byId[id])

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>Deliveries</div>
        <div className={styles.button}>
          <DeliveryAddButton/>
        </div>
      </div>
      <div className={styles.content}>
        <List
          columns={['Date/Time', 'Company', 'Volume']}
          status={status}
          rows={data.map(cur => ([
            f.DateTime(cur.createdAt),
            cur.company ? (byId[cur.company]?.name || cur.company) : 'self',
            f.L(cur.volume)
          ]))}
        />
      </div>
    </div>
  )
}