import { useState } from 'react'
import { http, hasViolations, securityContext } from '../http'
import { clsx } from '../helpers'
import styles from './LoginForm.module.css'

export const LoginForm = () => {
  const [variant, setVariant] = useState('signIn')

  const Component = {
    'signIn': SignIn,
    'signUp': SignUp,
  }[variant]

  return (
    <div className={styles.root}>
      <div className={styles.heading}>swirp</div>
      <div className={styles.tabs}>
        <div
          className={clsx(styles.tab, 'signIn' === variant && styles.current)}
          onClick={() => setVariant('signIn')}
          children={<span>I'm a client</span>}
        />
        <div
          className={clsx(styles.tab, 'signUp' === variant && styles.current)}
          onClick={() => setVariant('signUp')}
          children={<span>Become a client</span>}
        />
      </div>
      <Component/>
    </div>
  )
}

function SignIn() {
  const [error, setError] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [formValues, setFormValues] = useState({ username: '', password: '' })

  return (
    <form onSubmit={handleCommit} noValidate={true}>
      <div className={styles.controls}>
        <input
          type="text"
          className={styles.control}
          placeholder="Email"
          onChange={e => handleChange('username', e.target.value)}
        />
        <input
          type="password"
          className={styles.control}
          placeholder="Password"
          onChange={e => handleChange('password', e.target.value)}
        />
      </div>
      <button
        type="submit"
        className={styles.commit}
        children={isLoading ? 'Loading...' : 'Sign in'}
      />
      {error && <div className={styles.error}>{error}</div>}
    </form>
  )

  function handleCommit(e) {
    e.preventDefault()

    if (hasEmptyFields(formValues) || isLoading) {
      return
    }

    setLoading(true)

    http.fetch('/iam.token.create', formValues).then(res => {
      if (!res.ok) {
        setLoading(false)

        if ('9cb31350-c9e0-4d45-88ec-e680902a3417' === res.code) {
          setError(res.message)
        } else {
          setError('Something went wrong')
        }

        return
      }

      securityContext.logIn(res.data)
    })
  }

  function handleChange(name, value) {
    setFormValues(prev => ({ ...prev, [name]: value }))
  }
}

function SignUp() {
  const [errors, setErrors] = useState({})
  const [isLoading, setLoading] = useState(false)
  const [formValues,setFormValues] = useState({ username: '', 'password[first]': '', 'password[second]': '' })

  return (
    <form onSubmit={handleCommit} autoComplete="off" noValidate={true}>
      <div className={styles.controls}>
        <input
          type="text"
          className={styles.control}
          placeholder="Email"
          onChange={e => handleChange('username', e.target.value)}
        />
        {errors['username'] && <div className={styles.error}>{errors['username']}</div>}
        <input
          type="password"
          className={styles.control}
          placeholder="Password"
          onChange={e => handleChange('password[first]', e.target.value)}
        />
        {errors['password.first'] && <div className={styles.error}>{errors['password.first']}</div>}
        <input
          type="password"
          className={styles.control}
          placeholder="Confirm password"
          onChange={e => handleChange('password[second]', e.target.value)}
        />
      </div>
      <button className={styles.commit}>Sign up</button>
      {errors['root'] && <div className={styles.error}>{errors['root']}</div>}
    </form>
  )

  function handleChange(name, value) {
    setFormValues(prev => ({ ...prev, [name]: value }))
  }

  function handleCommit(e) {
    e.preventDefault()

    if (hasEmptyFields(formValues) || isLoading) {
      return
    }

    setLoading(true)

    http.fetch('/iam.consumer.create', formValues).then(res => {
      if (!res.ok) {
        setLoading(false)
        if (hasViolations(res)) {
          setErrors(res.errors.reduce((acc, cur) => ({ ...acc, [cur.property]: cur.message }), {}))
        } else {
          setErrors({ root: 'Something went wrong.' })
        }

        return
      }

      securityContext.logIn(res.data)
    })
  }
}

function hasEmptyFields(formValues) {
  return Object.keys(formValues).some(key => '' === formValues[key])
}