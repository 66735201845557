import { useSyncExternalStore } from 'react'
import { securityContext } from './http'
import { LoginForm } from './LoginForm'
import { Dashboard } from './Dashboard'

function App() {
  const isLoggedIn = useSyncExternalStore(securityContext.subscribe, securityContext.getSnapshot)

  if (false === isLoggedIn) {
    return <LoginForm/>
  }

  return <Dashboard/>
}

export default App