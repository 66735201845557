import styles from './Modal.module.css'
export const Modal = props => {
  const { children } = props

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  )
}