import styles from './Button.module.css'
import { clsx } from '../helpers'
export const Button = props => {
  const { size = 'md', variant = 'primary', disabled = false, ...rest } = props

  return (
    <div
      className={clsx(styles.root, styles[size], styles[variant], disabled && styles.disabled)}
      {...rest}
    />
  )
}