import { securityContext } from '../http'
import styles from './AppBar.module.css'
import { f } from '../helpers'
import { useStore } from './StoreProvider'
export const AppBar = () => {
  const [store] = useStore('inventory')

  return (

    <div className={styles.root}>
      <div>
        <div className={styles.logOut} onClick={() => securityContext.logOut()}>
          Logout
        </div>
      </div>
      <div className={styles.label}>swirp</div>
      <div className={styles.action}>
        Balance
        <span>
          {(() => {
            if ('loading' === store.status) {
              return '...'
            } else if ('rejected' === store.status || null == store.balance) {
              return 'N/A'
            } else {
              return f.L(store.balance)
            }
          })()}
        </span>
      </div>
    </div>
  )
}