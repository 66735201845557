import { StoreProvider } from './StoreProvider'
import { AppBar } from './AppBar'
import { DeviceList } from './DeviceList'
import { SupplierList } from './SupplierList'
import { DeliveryList } from './DeliveryList'

export const Dashboard = () => {
  return (
    <StoreProvider>
      <AppBar/>
      <DeviceList/>
      <SupplierList/>
      <DeliveryList/>
    </StoreProvider>
  )
}