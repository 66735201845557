import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useImmer } from 'use-immer'
import { http } from '../http'
import { useStore } from './StoreProvider'
import { Button } from './Button'
import { Modal } from './Modal'
import styles from './Form.module.css'

export const DeliveryAddButton = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Button onClick={() => setOpen(true)}>+</Button>
      {open && createPortal(
        <Modal>
          <Form onClose={() => setOpen(false)}/>
        </Modal>,
        document.body
      )}
    </>
  )
}

function Form(props) {
  const { onClose } = props
  const [store] = useStore('inventory')
  const [form, setForm] = useImmer({
    pending: false,
    fillUp: '',
    remainder: '',
    balance: store.balance / 1000,
  })

  return (
    <div className={styles.root}>
      <div className={styles.title}>Fill up</div>
      <div className={styles.content}>
        {form.error && <div className={styles.error}>Something went wrong.</div>}
        <div className={styles.group}>
          <div className={styles.label}>Known balance</div>
          <input
            className={styles.input}
            readOnly={true}
            defaultValue={form.balance}
            type="number"
          />
        </div>
        <div className={styles.group}>
          <div className={styles.label}>Fill up</div>
          <input
            className={styles.input}
            type="number"
            value={form.fillUp}
            onChange={e => handleFillUpChange(e.target.value)}
          />
        </div>
        <div className={styles.group}>
          <div className={styles.label}>New balance</div>
          <input
            className={styles.input}
            type="number"
            value={form.remainder}
            onChange={e => handleRemainderChange(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.actions}>
        <Button size="lg" variant="secondary" onClick={onClose}>Cancel</Button>
        <Button
          size="lg"
          children={form.pending ? 'Loading...' : 'Confirm'}
          disabled={form.pending || ('' === form.remainder && '' === form.fillUp)}
          onClick={handleCommit}
        />
      </div>
    </div>
  )

  function handleFillUpChange(fieldValue) {
    setForm(draft => {
      if ('' === fieldValue) {
        draft.fillUp = ''
        draft.remainder = ''
        return
      }

      const fillUp = Number(fieldValue)

      draft.fillUp = fieldValue
      draft.remainder = String(draft.balance + fillUp)
    })
  }

  function handleRemainderChange(fieldValue) {
    setForm(draft => {
      draft.fillUp = ''
      draft.remainder = fieldValue
    })
  }

  function handleCommit() {
    const payload = { volume: null }
    const { fillUp, remainder } = form

    if (fillUp) {
      payload.volume = Math.round(Number(fillUp) * 1000)
    } else if (remainder) {
      payload.volume = Math.round((Number(remainder) - form.balance) * 1000)
    }

    setForm(draft => {
      draft.pending = true
    })

    http.fetch('/app.delivery.create', payload).then(res => {
      if (!res.ok) {
        setForm(draft => {
          draft.pending = false
          draft.error = true
        })
        return
      }

      onClose()
    })
  }
}