import styles from './Card.module.css'
import { f } from '../helpers'
import { useStore } from './StoreProvider'
import { DeviceAddButton } from './DeviceAddButton'
import { List } from './List'

export const DeviceList = () => {
  const [store] = useStore('device.list')
  const status = store.status
  const data = store.ids.map(id => store.byId[id])

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>My pumps</div>
        <div className={styles.button}>
          <DeviceAddButton/>
        </div>
      </div>
      <div className={styles.content}>
        <List
          status={status}
          columns={['ID', 'Volume', 'Updated at']}
          rows={
            data.map(cur =>[
              cur.id,
              f.L(cur.volume),
              null == cur.updatedAt ? '—' : f.DateTime(cur.updatedAt)
            ])
          }
        />
      </div>
    </div>
  )
}